import layout1 from './layout1/Layout1Config';
import layout2 from './layout2/Layout2Config';
import layout3 from './layout3/Layout3Config';
import layoutAdmin from './layoutAdmin/LayoutAdminConfig';

const FuseLayoutConfigs = {
	layout1,
	layout2,
	layout3,
	layoutAdmin
};

export default FuseLayoutConfigs;
