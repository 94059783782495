import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useDispatch } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';

function QuickPanelToggleButton(props) {
	const dispatch = useDispatch();

	return (
        <IconButton
            className="w-64 h-64"
            onClick={ev => dispatch(Actions.toggleQuickPanel())}
            size="large">
			{props.children}
		</IconButton>
    );
}

QuickPanelToggleButton.defaultProps = {
	children: <Icon>format_list_bulleted</Icon>
};

export default QuickPanelToggleButton;
