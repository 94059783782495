import axios from 'app/client';
import _ from '@lodash';
import { responseError, responseErrors } from 'app/utils/helpers';
import { getUsersDataHack } from 'app/utils/hacks';
import { getUsers, getSelectedLicenseGroupId, getPendingUsersById, getUsersById, getLicenseGroups } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { User } from 'app/store/types';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';

export const addUsers = (emails: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	const usersData = getUsersDataHack(licenseGroupId, emails);

	try {
		// const responses = await Promise.all(_.chunk(usersData, 25).map(chunk => axios.post(`/api/v1/user`, chunk)));
		// TEMP::missing bulk delete so looping per action
		const responses = await Promise.all(usersData.map(userData => axios.post(`/api/v1/user`, userData)));

		// TODO::we're not handling `responseError` vs `responseErrors` correct in general - both in the shape they expect and which we use per action
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to add some users', 'warning'));
		} else {
			dispatch(appActions.alert('users updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const removeUsers = (emails: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const users = getUsers(getState());
	const pendingUsersById = getPendingUsersById(getState());

	// HACK-ish::split list back into "regular" and "pending" users
	const usersIds = emails
		.filter(email => !pendingUsersById[email])
		.map(email => users.find(user => user.email === email)!.id); // FIXME::by not using a dictionary this is horribly inefficient // HACK-ish:: use non-null assertion as email list comes from this list
	const pendingTokens = emails.filter(email => pendingUsersById[email]).map(email => pendingUsersById[email].token);

	try {
		// TEMP::missing bulk delete so looping per action
		const responses = await Promise.all(
			usersIds.map(userId => axios.delete(`/api/v1/user/${licenseGroupId}/${userId}`))
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to remove some users', 'warning'));
		} else {
			dispatch(appActions.alert('users removed', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const editUsers = (
	userIds: User['id'][],
	{ permissions: modifiedPermissions }: Partial<User>
): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const usersById = getUsersById(getState());

	const mergePermissions = (userId: User['id'], newPermissions: User['permissions'] | undefined) => {
		return {
			...usersById[userId].permissions,
			...newPermissions
		};
	};

	try {
		const responses = await Promise.all(
			_.chunk(userIds, 25).map(chunk =>
				axios.patch(
					`/api/v1/user/${licenseGroupId}`,
					chunk.map(userId => ({ userId, permissions: mergePermissions(userId, modifiedPermissions) }))
				)
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to update users', 'warning'));
		} else {
			dispatch(appActions.alert('users updated', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const flagUserVaultReset = (profileId: string): AppThunk => async (dispatch, getState) => {
	const licenseGroups = getLicenseGroups(getState());
	try {
		let req = profileId;
		if (licenseGroups !== undefined && licenseGroups.length > 0)
			req += `/${licenseGroups[0].id}`
		const response = await axios.patch(`/api/v1/user/flagvaultreset/${req}`);
		if (response.status === 200){
			window.location.reload();
			dispatch(licenseGroupsActions.getLicenseGroups()); 
			dispatch(appActions.alert('users updated', 'success'));
		}
		else {
			dispatch(appActions.alert('failed to update users', 'warning'));
		}
	}
	catch (error) {
		dispatch(appActions.handleError(error));
	}
}