import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import _ from '@lodash';
import type { RootState } from 'app/store';
import { DevicesState, DevicesActionTypes } from 'app/store/types';

const initialState: DevicesState = {};

const devicesReducer = (state = initialState, action: DevicesActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { devices }
			} = action.payload;

			return {
				...state,
				[licenseGroupId]: {
					byId: _.mapValues(devices, device => ({ ...device, name: `bizhub ${device.name}` }))
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'devices',
		storage: localForage
	},
	devicesReducer
);

// Selectors
export const getDevices = ({ app: { selectedLicenseGroupId }, devices }: RootState) =>
	Object.values(devices[selectedLicenseGroupId].byId);

export const getDevicesById = ({ app: { selectedLicenseGroupId }, devices }: RootState) =>
	devices[selectedLicenseGroupId].byId;

export const getDevicesByDeviceGroup = (
	{ app: { selectedLicenseGroupId }, devices }: RootState,
	deviceGroupId: string[]
) =>
	deviceGroupId
		.map(groupId =>
			Object.values(devices[selectedLicenseGroupId].byId).filter(item => item.deviceGroupId === groupId)
		)
		.flat();
