import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { ProfileState, ProfileActionTypes } from 'app/store/types';

const initialState: ProfileState = {
	info: undefined!, // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
	previousProfileId: undefined,
	reinviteUser: {}
};

const profileReducer = (state = initialState, action: ProfileActionTypes) => {
	switch (action.type) {
		case 'GET_PROFILE_SUCCESS': {
			const { data } = action.payload;
			return {
				...state,
				info: data?.user,
				previousProfileId: data?.user?.id ?? undefined
			};
		}
		case 'GET_VAULT_TYPE_SUCCESS': {
			const { data } = action.payload;
			return {
				...state,
				info: {
					...state.info,
					vaultType: data.vaultType
				}
			};
		}
		case 'REINVITE_USER': {
			return {
				...state,
				reinviteUser: action.payload
			};
		}
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'profile',
		storage: localForage,
		whitelist: ['previousProfileId'] // wipe user info on refresh
	},
	profileReducer
);

// Selectors
export const getProfile = ({ profile }: RootState) => profile.info;
export const getPreviousProfileId = ({ profile }: RootState) => profile.previousProfileId;
export const getIsOwnerProfile = (licenseGroupId: string) => (state: RootState) => {
	const profileId = getProfile(state)?.id;
	return profileId && profileId === state.licenseGroups.byId[licenseGroupId]?.ownerId;
};
export const getUserReinviteEmail = ({ profile }: RootState) => profile.reinviteUser;
