import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { ReportState, ReportActionTypes } from 'app/store/types';

const initialState: ReportState = {
	range: {
		dateRange: 'month',
		queryRange: 'day',
		zoomIn: false
	},
	filter: {
		type: 'device',
		ids: []
	},
	usersAndDevices: [],
	chartLoading: true,
	filterLoading: true,
	detailLoading: false,
	offset: {
		from: '',
		to: '',
		offset: ''
	}
};

const reportReducer = (state = initialState, action: ReportActionTypes) => {
	switch (action.type) {
		case 'SET_UTC_OFFSET': {
			return {
				...state,
				offset: action.payload.offset
			};
		}

		case 'SET_DATE_SPAN': {
			return {
				...state,
				dateSpan: action.payload.dateSpan
			};
		}

		case 'SET_DATE_RANGE': {
			const { dateRange, queryRange } = action.payload;
			return {
				...state,
				range: {
					...state.range,
					dateRange,
					queryRange
				}
			};
		}

		case 'GET_USERS_AND_DEVICES': {
			return {
				...state,
				filterLoading: true
			};
		}

		case 'GET_USERS_AND_DEVICES_SUCCESS': {
			return {
				...state,
				usersAndDevices: action.payload.usersAndDevices,
				filterLoading: false
			};
		}

		case 'SET_REPORT_FILTER_TYPE': {
			return {
				...state,
				filter: {
					...state.filter,
					type: action.payload.filter
				}
			};
		}

		case 'ADD_REPORT_FILTER': {
			return {
				...state,
				filter: {
					...state.filter,
					ids: [...state.filter.ids, action.payload.id]
				}
			};
		}

		case 'REMOVE_REPORT_FILTER': {
			return {
				...state,
				filter: {
					...state.filter,
					ids: state.filter.ids.filter(item => action.payload.id.id !== item.id)
				}
			};
		}

		case 'GET_REPORT_DATA': {
			return {
				...state,
				chartLoading: true
			};
		}

		case 'GET_REPORT_DATA_SUCCESS': {
			return {
				...state,
				reportData: action.payload.reportData,
				chartLoading: false
			};
		}

		case 'GET_FILTERED_REPORT_DATA': {
			return {
				...state
			};
		}

		case 'GET_FILTERED_REPORT_DATA_SUCCESS': {
			return {
				...state,
				filteredReportData: action.payload.filteredReportData,
				chartLoading: false
			};
		}

		case 'CLEAR_FILTERED_REPORT_DATA': {
			return {
				...state,
				filter: {
					...state.filter,
					ids: []
				},
				filteredReportData: undefined
			};
		}

		case 'GET_USER_OR_DEVICE_REPORT_DATA': {
			return {
				...state,
				detailLoading: true
			};
		}

		case 'GET_USER_OR_DEVICE_REPORT_DATA_SUCCESS': {
			return {
				...state,
				deviceOrUserDetail: action.payload.deviceOrUserDetail,
				detailLoading: false
			};
		}

		case 'CLEAR_USER_OR_DEVICE_REPORT_DATA': {
			return {
				...state,
				deviceOrUserDetail: undefined,
				detailLoading: false
			};
		}

		case 'SET_ZOOM_IN': {
			return {
				...state,
				range: {
					...state.range,
					zoomIn: action.payload
				}
			};
		}

		case 'SET_FILTER_LOADING': {
			return {
				...state,
				filterLoading: action.payload
			};
		}

		case 'SET_DETAIL_LOADING': {
			return {
				...state,
				detailLoading: action.payload
			};
		}

		case 'SET_CHART_LOADING': {
			return {
				...state,
				chartLoading: action.payload
			};
		}

		case 'RESET_REPORT_STATE': {
			return initialState;
		}

		case 'GET_TOP_USERS': {
			return {
				...state
			};
		}

		case 'GET_TOP_USERS_SUCCESS': {
			return {
				...state,
				topUsers: action.payload
			};
		}

		case 'GET_TOP_MFPS': {
			return {
				...state
			};
		}

		case 'GET_TOP_MFPS_SUCCESS': {
			return {
				...state,
				topMfps: action.payload
			};
		}

		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'report',
		storage: localForage,
		whitelist: []
	},
	reportReducer
);

// Selectors
export const getDateSpan = ({ report }: RootState) => report.dateSpan;
export const getRange = ({ report }: RootState) => report.range;
export const getFilter = ({ report }: RootState) => report.filter;
export const getUsersAndDevices = ({ report }: RootState) => report.usersAndDevices;
export const getReportData = ({ report }: RootState) => report.reportData;
export const getFilteredReportData = ({ report }: RootState) => report.filteredReportData;
export const getChartLoadingStatus = ({ report }: RootState) => report.chartLoading;
export const getFilterLoadingStatus = ({ report }: RootState) => report.filterLoading;
export const getDetailLoadingStatus = ({ report }: RootState) => report.detailLoading;
export const getUserOrDeviceDetail = ({ report }: RootState) => report.deviceOrUserDetail;
export const getOffset = ({ report }: RootState) => report.offset;
export const getTopUsers = ({ report }: RootState) => report.topUsers;
export const getTopMfps = ({ report }: RootState) => report.topMfps;
