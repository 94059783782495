import clsx from 'clsx';
import AppBar from '@mui/material/AppBar';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PurgeStateWrapper from 'app/components/PurgeStateWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getIsPossibleDevelopStore } from 'app/store/reducers';
import { useDispatch, useSelector } from 'app/modules/react-redux'
import * as Actions from 'app/store/actions'

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'rgba(30, 33, 37, 1)'
	},
	cookieSettingsLink: {
		color: '#FFFFFF !important',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	link: {
		color: '#FFFFFF !important'
	},
	separator: {
		backgroundColor: 'white',
		height: '16px',
		// width: '1px', Separator was too wide after mui5 update 
		margin: '0px'
	}
}));

function FooterLayout1(props) {
	const classes = useStyles();
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation();
	const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);

	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)

	const handleOnClick = () => {
		dispatch(Actions.toggleCookieSettingsDialog(true))
	}

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={footerTheme}>
                <AppBar id="fuse-footer" className={clsx(classes.root, 'relative z-10')}>
                    <Toolbar className="flex items-center container py-0 px-16 lg:px-24">
                        <div className="flex flex-1">
                            <Breadcrumbs separator={<Divider className={`${classes.separator}`} orientation="vertical" variant="middle" />} aria-label="breadcrumb">
                                {/* NOTE: The HELP Link might come back later since this is the only place we have the link for the help */}
                                {/* <Link
                                    className={classes.link}
                                    href={`${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {t('Help')}
                                </Link> */}
                                {/* TODO::add a contact page - maybe with a phone number as well? */}
                                {!isPossibleDevelopStore && (
                                    <Link
                                        className={classes.link}
                                        href="https://konicaminoltamarketplace.com/support/contact"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t('Contact Us')}
                                    </Link>
                                )}
                                <Link to='/#' className={classes.cookieSettingsLink} onClick={handleOnClick}>
                                    {t('Cookie Settings')}
                                </Link>
                                <RouterLink className={classes.link} to="/privacy">
                                    {t('Privacy')}
                                </RouterLink>
                                <RouterLink className={classes.link} to="/terms">
                                    {t('Terms and Conditions')}
                                </RouterLink>
                                <RouterLink className={classes.link} to="/eula">
                                    {t('EULA')}
                                </RouterLink>
                            </Breadcrumbs>
                        </div>

                        <div>
                            <Typography>
                                <PurgeStateWrapper>© {new Date().getFullYear()} Konica Minolta, Inc.</PurgeStateWrapper>
                            </Typography>
                        </div>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default React.memo(FooterLayout1);
