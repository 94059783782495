import FuseAnimate from '@fuse/core/FuseAnimate';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Icon from '@mui/material/Icon';
import { darken } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import React from 'react';
import { arrayify } from 'app/utils/helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	root: {
		background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`,
		color: theme.palette.primary.contrastText
	}
}));

type Props = {
	icon?: React.ReactNode;
	title: string;
	message: string[];
	adminPage?: boolean;
	footer?: React.ReactNode;
};
const MessageView = ({ icon = undefined, title, message = [], adminPage = false, footer = undefined }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<div className={clsx(classes.root, 'flex flex-col flex-auto flex-shrink-0 items-center justify-center p-32')}>
			<div className="flex flex-col items-center justify-center w-full">
				<FuseAnimate animation="transition.expandIn">
					<Card className="w-full max-w-384">
						<CardContent className="flex flex-col items-center justify-center p-32">
							{icon ? (
								<div className="m-32">
									<Icon className="text-96" color="action">
										{icon}
									</Icon>
								</div>
							) : (
								<div>
									<img
										className="w-128 m-32"
										src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.svg`}
										alt=""
									/>
								</div>
							)}

							<Typography variant="h5" className="text-center mb-16">
								{title}
							</Typography>

							{arrayify(message)
								.filter(Boolean)
								.map(line => (
									<Typography key={line} className="text-center mb-16 w-full" color="textSecondary">
										{line}
									</Typography>
								))}

							<div className="flex flex-col items-center justify-center pt-32 pb-24">
								{footer ||
									(adminPage ? (
										<Link className="font-medium" to="/admin">
											{t('Go to dashboard')}
										</Link>
									) : (
										<Link className="font-medium" to="/">
											{t('Go home')}
										</Link>
									))}
							</div>
						</CardContent>
					</Card>
				</FuseAnimate>
			</div>
		</div>
	);
};

export default MessageView;
