import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Popper, IconButton, Button, Box, ExtendButtonBase, IconButtonTypeMap, Grid } from '@mui/material';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import { getUsers } from 'app/store/reducers';
import { ExpiredIcon } from 'app/components/Icons';
import AssistUsersDialog from 'app/main/users/AssistUsersDialog';
import * as Actions from 'app/store/actions';
import { 
    getIsVaultUnlocked,
	getProfile,
	getUser
} from 'app/store/reducers';
import VaultDialog from '../VaultDialog';

const useStyles = makeStyles(theme => ({
	popper:{
		zIndex: "1299 !important" as any
	},
	notificationPopup: {
		color: theme.palette.common.white,
        backgroundColor: red[500],
		width: 'calc(100vw - 280px - 20px)',
		padding: '1.2rem 1.2rem 1.2rem 2.5rem',
		marginLeft: 'auto',
		position: 'fixed',
		right: '16px',
		top: '4.6em',
		display: 'grid',
		gridTemplateColumns: '6em 1fr 8em',
		gridTemplateRows: 'auto',
		borderRadius: '4px',
		boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
	},
	closeButtonWrapper: {
		position: 'absolute',
		top: 0,
		right: 0,
		margin: '0.2rem'
	},
	errorIcon: {
		height: '60px',
		width: '60px',
		color: 'white',
		gridColumnStart: '1',
		gridRow: '1/3',
		alignSelf: 'center'
	},
	textContent: {
		margin: '0 auto',
		gridRow: '2/3',
		gridColumn: '2/3',
		alignSelf: 'center'
	},
	reinvite: {
		gridRow: '2/3',
		gridColumn: '3/4',
		width: '100%'
		
	}
}));

const RecoveryNotification = () => {
    const { t } = useTranslation();
    const classes = useStyles();
	const users = useSelector(getUsers);

	const dispatch = useDispatch();
	const currentUser = useSelector(getProfile);
	const usersNeedAssistance = users?.filter(item => item.vaultReset && item.id !== currentUser.id);
	const currentUserStatus = useSelector(getUser(currentUser.id));
    const [anchorEl, setAnchorEl] = useState<ExtendButtonBase<IconButtonTypeMap<{}, "button">> | null>(null);
	const [openNotification, setOpenNotification] = useState(true);
	const [userNotification, setUserNotification] = useState(0);
	const [assistUsersDialogOpen, setAssistUsersDialogOpen] = useState(false);
	const testRef = React.useRef<HTMLButtonElement | null>(null);
	const isVaultUnlocked = useSelector(getIsVaultUnlocked);
	const [vaultLockedDialog, setVaultLockedDialog] = useState<boolean>(false);
	const userVaultType = useSelector(getProfile);
	
	const handleClick = () => {
		if (isVaultUnlocked){
			dispatch(Actions.reinviteUser(usersNeedAssistance[0]))
		} else {
			setVaultLockedDialog(true)
		}
		setAssistUsersDialogOpen(true);
	};

	const submitUnlock = () => {
		if (isVaultUnlocked !== undefined){
			setAssistUsersDialogOpen(true)
			dispatch(Actions.reinviteUser(usersNeedAssistance[0]))
		}
		else 
			setAssistUsersDialogOpen(false)
	}

    const handleClose = () => {
		setAnchorEl(null);
		setOpenNotification(false);
	};

	const handleNext = () => {
		setUserNotification(prevActiveNotification => prevActiveNotification + 1);
	};

	const handleBack = () => {
		setUserNotification(prevActiveNotification => prevActiveNotification - 1);
	};

    return <>
        {/* <IconButton className="w-64 h-64 text-white" onClick={handleClick} ref={testRef}>
            {/* <Badge color="error"> }
                <PriorityHighIcon />
            {/* </Badge> }
        </IconButton> */}
        <Popper
            className={classes.popper}
            open={!currentUserStatus?.vaultReset && openNotification && usersNeedAssistance?.length > 0 && users.length > 1 }
            // onClose={handleClose}
            // anchorOrigin={{
            // 	vertical: 'top',
            // 	horizontal: 'center'
            // }}
            // transformOrigin={{
            // 	vertical: 'top',
            // 	horizontal: 'left'
            // }}
        >
            <div className={classes.notificationPopup}>
                <ExpiredIcon className={classes.errorIcon} />
                <div className="mb-0 flex items-center">
                    <Typography className="my-0 mx-auto text-4xl" variant="h6">
                        {t('vault:vault access')}
                    </Typography>
                    {/* <div className='flex items-center'
                    >
                        <Typography className="pr-16" variant="h6">
                            <span className="font-normal">
                                {userNotification + 1}/{usersNeedAssistance.length}
                            </span>
                        </Typography>
                        <div className="pr-16">
                            <IconButton 
                                color="inherit"
                                onClick={handleBack}
                                disabled={userNotification === 0}
                            >
                                <KeyboardArrowLeft />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={handleNext} 
                                disabled={userNotification === usersNeedAssistance.length - 1}
                            >
                                <KeyboardArrowRight />
                            </IconButton>
                        </div>
                    </div> */}
                </div>
                    <Box className={classes.textContent}>
                        <Typography className='my-0 mx-auto'>
                            {usersNeedAssistance && usersNeedAssistance[userNotification]?.firstName + ' ' + usersNeedAssistance[userNotification]?.lastName + ' '}
                            {t('vault:help tenant user')}
                            {/* <Button 
                                style={{textTransform: 'none', padding: 'none'}}
                                color='inherit'
                                variant='text'
                                onClick={() => {setAssistUsersDialogOpen(prev => !prev); setOpenVault(true);}}
                                >
                                {t('vault:help tenant user')}
                            </Button> */}
                        </Typography>

                    </Box>
                    <Button 
                                className={classes.reinvite}
                                style={{ padding: 'none'}}
                                color='inherit'
                                variant='text'
                                onClick={() => handleClick()}
                                >
                                {t('vault:reinvite')}
                    </Button> 
                <div className={classes.closeButtonWrapper}>
                    <IconButton onClick={handleClose} color="inherit" size="large">
                        <CloseIcon />
                    </IconButton>
                </div>
            </div>
            {assistUsersDialogOpen ? (
                isVaultUnlocked ? (
                    <AssistUsersDialog
                        open={assistUsersDialogOpen}
                        handleClose={() => setAssistUsersDialogOpen(false)}
                    />
                ) : (
                    <VaultDialog open={vaultLockedDialog} setOpen={setVaultLockedDialog} onClose={submitUnlock} />
                )
            ) : null}
        </Popper>
    </>;
}

export default RecoveryNotification;