import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReactMarkdown, { Options as ReactMarkdownProps } from 'react-markdown';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	textContainer: {
		'& p, & ul, & ol, & h1, & h2, & h3, & h4, & h5, & h6': { marginBottom: '1em' },
		'& li': { marginBottom: '0.5em' },
		'& ul > li': {
			listStyleType: 'disc'
		},
		'& ol > li': {
			listStyleType: 'decimal'
		},
		'& ol > ol > li': {
			listStyleType: 'lower-latin',
			marginLeft: 'em(60px)'
		},
		'& ul > ol > li': {
			listStyleType: 'lower-latin',
			marginLeft: 'em(60px)'
		},
		'& ol > ul > li': {
			listStyleType: 'circle',
			marginLeft: 'em(60px)'
		},
		'& ul > ul > li': {
			listStyleType: 'circle',
			marginLeft: 'em(60px)'
		},
		'& ol > ol > ol > li': {
			listStyleType: 'lower-roman'
		},
		'& ol > ul > ol > li': {
			listStyleType: 'lower-roman'
		},
		'& ul > ol > ol > li': {
			listStyleType: 'lower-roman'
		},
		'& ul > ul > ol > li': {
			listStyleType: 'lower-roman'
		},
		'& ol > ul > ul > li': {
			listStyleType: 'square'
		},
		'& ol > ol > ul > li': {
			listStyleType: 'square'
		},
		'& ul > ol > ul > li': {
			listStyleType: 'square'
		},
		'& ul > ul > ul > li': {
			listStyleType: 'square'
		},
		'& br': {
			display: 'block',
			marginTop: 'em(10px)',
			content: ' '
		}
	}
}));

type Props = ReactMarkdownProps & { className?: string };

const Markdown = ({ className, ...props }: Props) => {
	const classes = useStyles();

	return <ReactMarkdown className={clsx(className, classes.textContainer)} {...props} />;
};

export default Markdown;
