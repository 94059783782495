import axios from 'app/client';
import _ from '@lodash';
import { responseError, responseErrors } from 'app/utils/helpers';
import { parseJsonHack } from 'app/utils/hacks';
import { getSelectedLicenseGroupId, getProfile } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import { AlertSubscription } from '../types';

// HACK::reshape data returned from backend
const massageRawAlertSubscription = (rawAlertSubscription: any) => {
	const alertSubscription: AlertSubscription = {
		id: rawAlertSubscription.SK,
		userId: rawAlertSubscription.userId,
		type: rawAlertSubscription.type,
		IdForType: rawAlertSubscription.id,
		severity: rawAlertSubscription.severity,
		alertSite: rawAlertSubscription.alertSite,
		alertEmail: rawAlertSubscription.alertEmail,
		alertCell: rawAlertSubscription.alertCell,
		dateCreated: rawAlertSubscription.createdAt,
		dateUpdated: rawAlertSubscription.updatedAt
	};

	return alertSubscription;
};


export const getAlertSubscriptions = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	try {
		const { data: rawAlertSubscriptions } = await axios.get(
			`/api/v1/alert/${licenseGroupId}/user/${profile.id}/subscriptions`
		);

		// HACK::apply various hacks
		const alertSubscriptions = parseJsonHack(rawAlertSubscriptions).map(massageRawAlertSubscription);

		dispatch({
			type: 'GET_ALERT_SUBSCRIPTIONS_SUCCESS',
			payload: {
				licenseGroupId,
				data: _.keyBy(alertSubscriptions, 'id')
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

type AlertSubscriptionOptions = {
	type?: 'device' | 'policy' | 'user' | 'report-device-status';
	IdForType?: string; // e.g. if `type` is "device" then this is the (optional) specific deviceId
	severity?: 'high' | 'low' | 'medium' | 'info';
	alertSite: boolean;
	alertEmails: string[];
	alertCells: string[];
	parameters?: {
		reportTime?: string;
		assessedNotSecure?: boolean;
		assessedOffline?: boolean;
		notAssessed?: boolean;
	};
};
export const createAlertSubscription = ({
	type,
	IdForType,
	severity,
	alertSite,
	alertEmails = [],
	alertCells = [],
	parameters
}: AlertSubscriptionOptions): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	const data = {
		tenantId: licenseGroupId,
		userId: profile.id,
		type,
		id: IdForType,
		severity,
		alertSite,
		alertEmail: alertEmails,
		alertCell: alertCells,
		parameters
	};

	try {
		const response = await axios.post(`/api/v1/alert/subscribe`, data);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to create alert subscription', 'warning'));
		} else {
			dispatch(appActions.alert('alert subscription created', 'success'));
		}
		dispatch(getAlertSubscriptions());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const deleteAlertSubscription = (alertSubscriptionId: AlertSubscription['id']): AppThunk => async (
	dispatch,
	getState
) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		const response = await axios.delete(`/api/v1/alert/${licenseGroupId}/unsubscribe/${alertSubscriptionId}`);
		// @ts-ignore
		if (responseError(response)) {
			dispatch(appActions.alert('failed to delete subscription', 'warning'));
		} else {
			dispatch(appActions.alert('alert subscription deleted', 'success'));
		}
		dispatch(getAlertSubscriptions());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
