import axios from 'app/client';
import { getPreviousProfileId, getProfile as getProfileReducer } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import * as appActions from './app.actions';
import * as vaultActions from './vault.actions';
import { Profile, User, VaultType } from '../types';

export const getProfile = (): AppThunk => async (dispatch, getState) => {
	const previousProfileId = getPreviousProfileId(getState());

	try {
		const { data } = await axios.get<
			any,
			{
				data: {
					user: Profile;
					vaultUrl: string;
				};
			}
		>('/api/sso/session');
		const { user, vaultUrl } = data;
		dispatch({
			type: 'GET_PROFILE_SUCCESS',
			payload: {
				data: { user }
			}
		});
		if (user && previousProfileId && user.id !== previousProfileId) {
			dispatch({
				type: 'LOGGED_OUT_USER'
			});
			return;
		}
		dispatch(vaultActions.getUserVault(vaultUrl));
	} catch (error) {
		if ((error as any).response?.status === 401) {
			// if user not logged in
			dispatch({
				type: 'GET_PROFILE_SUCCESS',
				payload: {
					data: undefined
				}
			});
			dispatch({
				type: 'LOGGED_OUT_USER'
			});
			return;
		}
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};

export const getVaultType = (): AppThunk => async (dispatch, getState) => {
	const profile = getProfileReducer(getState());
	const response = await axios.get<
		any,
		{
			status: number;
			data: {
				vaultType: VaultType;
			};
		}
	>(`/api/v1/vault/type/${profile.id}`);
	if (response.status > 400) {
		dispatch(appActions.alert('Unable to get vault type', 'error'));
		dispatch({
			type: 'GET_VAULT_TYPE_SUCCESS',
			payload: {
				data: { vaultType: undefined }
			}
		});
	}
	dispatch({
		type: 'GET_VAULT_TYPE_SUCCESS',
		payload: {
			data: { vaultType: response.data.vaultType }
		}
	});
};

export const reinviteUser = (data: Partial<User>) => {
	return {
		type: 'REINVITE_USER',
		payload: {
			email: data.email,
			id: data.id,
			firstName: data.firstName,
			lastName: data.lastName
		}
	};
};
