import _ from '@lodash';
import axios from 'app/client';
import { getRegion } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { CatalogItem, OrderType, PublicId } from 'app/store/types';
import * as appActions from './app.actions';

// HACK::publicId can come in upper- or lower-case
const normalizePublicId = (obj: { [publicId in PublicId]: CatalogItem }) =>
	Object.entries(obj).reduce(
		(acc, [key, value]) => ({
			...acc,
			[key.toUpperCase() as PublicId]: {
				...value,
				publicId: value.publicId.toUpperCase() as PublicId
			}
		}),
		{}
	);

export const getCatalog = (): AppThunk => async (dispatch, getState) => {
	const region = getRegion(getState());

	try {
		const {
			data: { currencyCode, pricing }
		} = await axios.get(`/api/mp/catalog/pricing?region=${region.length > 2 ? 'US' : region}`);
		dispatch({
			type: 'GET_IS_SUPPORTED_REGION_SUCCESS',
			payload: {
				isSupportedRegion: true
			}
		});
		dispatch({
			type: 'GET_CATALOG_SUCCESS',
			payload: {
				currency: currencyCode,
				catalog: _.mapValues(
					normalizePublicId(pricing),
					// for simplicity, add "defaultPrice" since ATM Personalize has no price ranges
					(catalogItem: CatalogItem) => ({
						...catalogItem,
						defaultPrice: catalogItem.priceRanges![0]!.amount
					})
				)
			}
		});
	} catch (error) {
		const u = error as any;
		// if banned or unknown region
		if (u.response?.status === 403 || u.response?.status === 404) {
			dispatch({
				type: 'GET_IS_SUPPORTED_REGION_SUCCESS',
				payload: {
					isSupportedRegion: false
				}
			});
			return;
		}
		dispatch(appActions.handleError(u));
		// re-throw error for handling in <InitializeApp />
		throw u;
	}
};

type TStartCheckoutOptions = { quantity: number };
export const startCheckout = (
	publicId: PublicId,
	{ quantity }: TStartCheckoutOptions,
	orderType: OrderType = 'PRODUCTION'
): AppThunk => async dispatch => {
	try {
		const {
			data: { checkoutUrl }
		} = await axios.post('/api/mp/checkout/session', {
			catalogItems: {
				[publicId]: { quantity }
			},
			orderType
		});
		window.location.assign(checkoutUrl);
	} catch (error) {
		//why does (error: any | unknown) not work...
		const u = error as any;
		if (u.response?.status === 401) {
			// if user not logged in
			const redirectPath = `/pricing?checkout=${JSON.stringify({ publicId, quantity })}`;
			window.location.assign(`/api/sso/login?redirect=${encodeURIComponent(redirectPath)}`);
			return;
		}

		dispatch(appActions.handleError(u));
	}
};
