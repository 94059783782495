import AppBar from '@mui/material/AppBar';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PurgeStateWrapper from 'app/components/PurgeStateWrapper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getIsPossibleDevelopStore } from 'app/store/reducers';
import { useDispatch, useSelector } from 'app/modules/react-redux'
import * as Actions from 'app/store/actions'

//
/// Styles
///
const useStyles = makeStyles(theme =>
	createStyles({
	link: {
			color: '#61dafb',
			'&:hover': {
				cursor: 'pointer'
			}
		}
	})
)

function FooterLayoutAdmin(props) {
	const classes = useStyles()
	const dispatch = useDispatch()
	const { t, i18n } = useTranslation();
	const footerTheme = useSelector(({ fuse }) => fuse.settings.footerTheme);

	const isPossibleDevelopStore = useSelector(getIsPossibleDevelopStore); // TEMP::skip any buttons for now develop store (as we don't know which to direct to)

	const handleOnClick = () => {
		dispatch(Actions.toggleCookieSettingsDialog(true))
	}

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={footerTheme}>
                <AppBar
                    id="fuse-footer"
                    className="relative z-10"
                    color="default"
                    style={{ backgroundColor: footerTheme.palette.background.default }}
                >
                    <Toolbar className="flex items-center container py-0 px-16 lg:px-24">
                        <div className="flex flex-1">
                            <Typography>
                                <PurgeStateWrapper>© 2021 Konica Minolta, Inc.</PurgeStateWrapper>
                            </Typography>
                        </div>

                        <div>
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                <Link
                                    color="inherit"
                                    href={`${process.env.REACT_APP_HELP_URL}?locale=${i18n.language}`}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    {t('Help')}
                                </Link>
                                {/* TODO::add a contact page - maybe with a phone number as well? */}
                                {!isPossibleDevelopStore && (
                                    <Link
                                        color="inherit"
                                        href="https://konicaminoltamarketplace.com/support/contact"
                                        target="_blank"
                                        rel="noreferrer noopener"
                                    >
                                        {t('Contact Us')}
                                    </Link>
                                )}
                                <RouterLink to="/privacy">{t('Privacy')}</RouterLink>
                                <Link to='/#' className={classes.link} onClick={handleOnClick}>
                                {t('Cookie Settings')}
                                </Link>
                                <RouterLink to="/terms">{t('Terms and Conditions')}</RouterLink>
                            </Breadcrumbs>
                        </div>
                    </Toolbar>
                </AppBar>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default React.memo(FooterLayoutAdmin);
