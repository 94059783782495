import { CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import axios from 'app/client';
import { green } from '@mui/material/colors';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import * as Actions from 'app/store/actions';
import { useDispatch, useSelector } from 'app/modules/react-redux';
import { getLockedUserVault, getProfile } from 'app/store/reducers';
import { LicenseGroupData, VaultLicenseGroupData } from 'app/store/types';

interface Props {
    filteredLicenseGroups: Record<LicenseGroupData['id'], VaultLicenseGroupData>
    licenseGroups: Record<LicenseGroupData['id'], VaultLicenseGroupData>
    close: () => void;
}

const AppManagedUnlockDialog = (
    { filteredLicenseGroups, licenseGroups, close }: Props) => {
    const [loading, setLoading] = React.useState(true);
    const [open, setOpen] = React.useState(true);

    const dispatch = useDispatch();

    const profile = useSelector(getProfile);
    const profileId = profile?.id;
    const lockedUserVault = useSelector(getLockedUserVault);


    React.useEffect(() => {
        axios.get(`/api/v1/vault/appManage/${profileId}`).then(response => {
            const password = response.data.password;
            dispatch(
                Actions.decryptVaults(
                    password,
                    licenseGroups,
                    filteredLicenseGroups,
                    lockedUserVault,
                    () => {
                        dispatch(Actions.alert('Vault Unlocked', 'success'));
                        setLoading(false);
                        setOpen(false);
                        close();
                    },
                    () => {
                        dispatch(Actions.alert('Error unlocking vault', 'error'));
                        setLoading(false);
                    }
                )
            );
        });
    }, []);

    return (
        <Dialog open={open} fullWidth maxWidth="xs">
            <DialogContent>
                <DialogTitle className='mb-12'><div className="flex flex-1 justify-center">
                    <div className='mr-32'>{"Unlocking vault..."}</div>
                    <CircularProgress size={32} style={{ color: green[500] }} />
                </div></DialogTitle>

            </DialogContent>
        </Dialog>
    );
};

export default AppManagedUnlockDialog;
