import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector } from 'app/modules/react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		'& .logo-icon': {
			width: 24,
			height: 24,
			transition: theme.transitions.create(['width', 'height'], {
				duration: theme.transitions.duration.shortest,
				easing: theme.transitions.easing.easeInOut
			})
		},
		'& .logo-text': {
			marginLeft: '1.4rem',
			width: 182,
			maxWidth: 'unset'
		}
	}
}));

type Props = {
	link?: boolean;
};
const Logo = ({ link = false }: Props) => {
	const { adminPage } = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const { t } = useTranslation();
	const classes = useStyles();

	const linkComponent = (
		<div className={clsx(classes.root, 'flex items-center')}>
			<img
				className="logo-icon transform scale-150"
				src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.svg`}
				alt={t('Shield Guard')}
			/>
			<div>
				<img
					className="logo-text"
					src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-text-only.svg`}
					alt={t('Shield Guard')}
				/>
			</div>
		</div>
	);

	return link ? <Link to={adminPage ? '/admin/dashboard' : '/'}>{linkComponent}</Link> : linkComponent;
};

export default Logo;
