import React, { useState } from 'react';
import { VaultType } from 'app/store/types';
import i18n from 'i18n';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	FormControl,
	TextField,
	MenuItem,
	DialogActions
} from '@mui/material';
import HelpPageButton from 'app/components/HelpPageButton';
import PasswordWarning from './PasswordWarning';
import LoadingButton from 'app/components/LoadingButton';
import PasswordTextField from 'app/components/PasswordTextField';
import SecurityMeter from 'app/components/SecurityMeter';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'app/modules/react-redux';
import { canAppManage, getIsVaultCreated, getLicenseGroups, getProfile } from 'app/store/reducers';

type Props = {
	open: boolean;
	onCreate: (e: any, password: string | ArrayBuffer, selectedVaultType: VaultType) => void;
	close: () => void;
	preventClose: boolean;
	pendingForm: boolean;
	password: string | ArrayBuffer;
	setPassword: (password: string | ArrayBuffer) => void;
};

const CreateVaultDialog = ({ open, pendingForm, onCreate, close, preventClose, password, setPassword }: Props) => {
	const { t } = useTranslation();
	const isVaultCreated = useSelector(getIsVaultCreated);
	const licenseGroups = useSelector(getLicenseGroups);
	const userProfile = useSelector(getProfile);
	const userCanAppManage = useSelector(canAppManage);
	const userVaultType = userProfile.vaultType;
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [selectedVaultType, setSelectedVaultType] = useState<VaultType>(userVaultType || 'self');

	let disabled =
		(password === '' || passwordConfirmation === '' || passwordConfirmation !== password) &&
		selectedVaultType === 'self';

	const isFirstAdmin = licenseGroups.length === 1 && licenseGroups[0].ownerId === userProfile.id;

	const VaultTypeMessage =
		selectedVaultType === 'app' ? (
			<DialogContentText>
				{`${t('vault:type app-managed')} - ${t('vault:type app-managed description')} ${t('online help')}`}
			</DialogContentText>
		) : (
			<DialogContentText className="mb-32">{t('vault:create self-managed message')}</DialogContentText>
		);

	return (
		<Dialog open={open} onClose={() => !preventClose && close()} fullWidth maxWidth="xs">
			<DialogTitle id="vault-dialog-title">
				<div className="flex justify-between">
					{t('vault:create title')}
					<HelpPageButton section="createVault" />
				</div>
			</DialogTitle>
			<form onSubmit={e => onCreate(e, password, selectedVaultType)}>
				<DialogContent>
					{licenseGroups.length > 0 ? (
						isFirstAdmin ? (
							<>
								<DialogContentText className="mb-32">
									{t('vault:create message:regular')}
								</DialogContentText>
								<DialogContentText className="mb-32">
									{t('vault:create message:firstTime')}
								</DialogContentText>
							</>
						) : !userCanAppManage ? (
							<>
								<DialogContentText className="mb-32">
									{t('vault:create message:restricted note')}
								</DialogContentText>
								{/*order of these two paragraphs was swapped for the restricted vault creation*/}
								<DialogContentText className="mb-32">
									{t('vault:create message:regular')}
								</DialogContentText>
							</>
						) : (
							<>
								<DialogContentText className="mb-32">
									{t('vault:create message:regular')}
								</DialogContentText>
								<FormControl className="w-full mb-32">
									<TextField
										select
										label={t('vault:create type label')}
										variant="outlined"
										value={selectedVaultType}
										disabled={!userCanAppManage}
										onChange={e => setSelectedVaultType(e.target.value as VaultType)}
									>
										<MenuItem value="self">{t('vault:type self-managed')}</MenuItem>
										<MenuItem value="app">{t('vault:type app-managed')}</MenuItem>
									</TextField>
								</FormControl>
								<div className="mb-32">
									<div className="mb-10">
										<SecurityMeter type={selectedVaultType} />
									</div>
								</div>
								{VaultTypeMessage}
							</>
						)
					) : (
						<>
							<DialogContentText className="mb-32">
								{t('vault:create message:regular')}
							</DialogContentText>
						</>
					)
					}
					{(selectedVaultType === 'self' || isFirstAdmin || !userCanAppManage) && (
						<>
							<PasswordWarning />
							<PasswordTextField
								autoFocus
								className="mt-8 mb-8"
								variant="outlined"
								label={t('vault:password label')}
								fullWidth
								autoComplete={!isVaultCreated ? 'new-password' : 'current-password'}
								value={password}
								onChange={event => setPassword(event.target.value)}
							/>
							<PasswordTextField
								className="mb-8"
								variant="outlined"
								label={t('vault:confirm password label')}
								fullWidth
								autoComplete="new-password"
								value={passwordConfirmation}
								onChange={event => setPasswordConfirmation(event.target.value)}
								error={passwordConfirmation !== '' && passwordConfirmation !== password}
								helperText={
									passwordConfirmation !== '' && passwordConfirmation !== password
										? t('change password:new password mismatch')
										: undefined
								}
							/>
						</>
					)}
				</DialogContent>
				<DialogActions>
					<LoadingButton type="submit" loading={pendingForm} disabled={disabled}>
						{t('Create')}
					</LoadingButton>
				</DialogActions>
			</form>
		</Dialog>
	);
};
export default CreateVaultDialog;
