import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { MarketState, MarketActionTypes } from 'app/store/types';

const initialState: MarketState = {
	currency: undefined!, // HACK::assume never undefined and handle check only in <InitializeApp />
	catalog: undefined! // HACK::assume never undefined and handle check only in <InitializeApp />
};

const marketReducer = (state = initialState, action: MarketActionTypes) => {
	switch (action.type) {
		case 'GET_CATALOG_SUCCESS': {
			const { currency, catalog } = action.payload;
			return {
				...state,
				currency,
				catalog
			};
		}
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'market',
		storage: localForage,
		whitelist: [] // don't store any of this ATM (might want to to speed up loading at some point...)
	},
	marketReducer
);

// Selectors
export const getCurrency = ({ market }: RootState) => market.currency;
export const getCatalog = ({ market }: RootState) => market.catalog;
