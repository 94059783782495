import { Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import clsx from 'clsx';
import { VaultType } from 'app/store/types';
import { useTranslation } from 'react-i18next';
import { ExpandLess as ArrowIcon } from '@mui/icons-material';

type Props = {
	type: VaultType;
};

const useStyles = makeStyles(theme => ({
	bar: {
		paddingLeft: '2rem',
		color: 'white'
	},
	securityBar: {
		background: '#80bc26'
	},
	convenienceBar: {
		background: '#39807c'
	},
	barHigh: {
		width: '100%'
	},
	barLow: {
		width: '95%'
	},
	arrowLeft: {
		transform: 'rotate(270deg)'
	},
	arrowRight: {
		transform: 'rotate(90deg)'
	}
}));

const SecurityMeter = ({ type }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<>
			<Grid container xs={12}>
				<Grid item xs={12}>
					<div
						className={clsx(
							classes.bar,
							classes.securityBar,
							type === 'self' ? classes.barHigh : classes.barLow
						)}
					>
						{t('vault:security graph:security')}
					</div>
				</Grid>
				<Grid item xs={12}>
					<div
						className={clsx(
							classes.bar,
							classes.convenienceBar,
							type === 'self' ? classes.barLow : classes.barHigh
						)}
					>
						{t('vault:security graph:convenience')}
					</div>
				</Grid>
			</Grid>
		</>
	);
};

export default SecurityMeter;
