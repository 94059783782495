import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { getLicenseGroups, getSelectedLicenseGroupData } from 'app/store/reducers';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import * as Actions from 'app/store/actions';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

const LicenseGroupsMenu = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const licenseGroups = useSelector(getLicenseGroups);
	const licenseGroupData = useSelector(getSelectedLicenseGroupData);

	const [menu, setMenu] = useState<HTMLButtonElement | null>(null);

	const menuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setMenu(event.currentTarget);
	};

	const menuClose = () => {
		setMenu(null);
	};

	const location = useLocation();
	const history = useHistory()
	const locationRef = React.useRef(location);

	const selectLicenseGroup = (licenseGroupId: string) => {
		//removes the tenantId from the path that was set after opening the vault reset email.
		//if not removed you can't select a different tenant.
		dispatch(Actions.setSelectedLicenseGroup(licenseGroupId));
		const regex = /admin\/users\/([^\/]+$)/;
		const match = regex.test(locationRef.current.pathname);
		if(match)
			history.push('/admin/users');
	};

	return <>
        <Button className="h-64 px-20 text-white" onClick={menuClick}>
            <Icon className="text-green-accent">business</Icon>

            <div className="flex-col mx-12 items-start">
                <Typography component="span" className="normal-case font-600 flex">
                    {licenseGroupData.name}
                </Typography>
            </div>

            <Icon className="text-green-accent">keyboard_arrow_down</Icon>
        </Button>

        <Popover
            open={!!menu}
            anchorEl={menu}
            onClose={menuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
            classes={{
                paper: 'py-8'
            }}
        >
            {licenseGroups
                .filter(licenseGroup => licenseGroup.id !== licenseGroupData.id)
                .map(licenseGroup => (
                    <MenuItem
                        key={licenseGroup.id}
                        onClick={() => {
                            menuClose();
                            selectLicenseGroup(licenseGroup.id);
                        }}
                        role="button"
                    >
                        <ListItemIcon sx={{width: '56px'}}>
                            <Avatar>{licenseGroup.name.replace(/[^\w]/gi, '')[0] ?? licenseGroup.name[0]}</Avatar>
                        </ListItemIcon>
                        <ListItemText primary={licenseGroup.name} />
                    </MenuItem>
                ))}
        </Popover>
    </>;
};

export default LicenseGroupsMenu;
