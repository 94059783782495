import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import React from 'react';

function RightSideLayoutAdmin() {
	return (
		<>
			<QuickPanel />
		</>
	);
}

export default React.memo(RightSideLayoutAdmin);
