import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { getDevices } from 'app/store/reducers';
import { VaultState, VaultActionTypes, Policy } from '../types';

const isMockVault = localStorage.getItem('mockVault');

// initial state of vault
const getMockVault = (state: RootState) => {
	return {
		devices: getDevices(state).reduce((acc, device, i) => {
			// "randomly" return one unknown password
			if (i === getDevices(state).length - 2) {
				return acc;
			}

			return new Map([
				...acc,
				[
					device.serial,
					{
						adminPassword: `P@ssw0rd${device.serial.slice(-4)}`
					}
				]
			]);
		}, new Map())
	};
};

const initialState: VaultState = {};

const vaultReducer = (state = initialState, action: VaultActionTypes) => {
	switch (action.type) {
		case 'CREATE_VAULTS_SUCCESS': {
			const { userVault, groupVaults } = action.payload;
			return {
				...state,
				userVault,
				groupVaults
			};
		}
		case 'DECRYPT_VAULTS_SUCCESS': {
			const { userVault, groupVaults } = action.payload;
			return {
				...state,
				userVault,
				groupVaults
			};
		}
		case 'GET_VAULTS_SUCCESS': {
			const { lockedUserVault } = action.payload;
			return {
				...state,
				lockedUserVault
			};
		}
		case 'GET_VAULT_DATA_SUCCESS': {
			const { userVault, groupVaults } = action.payload;

			return {
				...state,
				userVault,
				groupVaults: new Map(groupVaults.entries())
			};
		}
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'vault',
		storage: localForage,
		whitelist: []
	},
	vaultReducer
);

// Selectors
export const getUserVault = ({ vault }: RootState) => vault.userVault;
export const getLockedUserVault = ({ vault }: RootState) => vault.lockedUserVault;
export const getIsVaultUnlocked = ({ vault }: RootState) => vault.userVault !== undefined;
export const getIsVaultCreated = ({ vault }: RootState) => vault.lockedUserVault !== undefined;
export const getSelectedLicenseGroupVault = ({ app: { selectedLicenseGroupId }, vault }: RootState) =>
	vault.groupVaults?.get(selectedLicenseGroupId);
export const getDevicePasswords = (state: RootState) =>
	isMockVault ? getMockVault(state).devices : getSelectedLicenseGroupVault(state)?.vault.devices;
export const getDevicePassword = (serial: string) => (state: RootState) =>
	getDevicePasswords(state)?.get(serial)?.adminPassword;
export const getAdminPasswordByPolicyId = (policyId: Policy['id']) => (state: RootState) =>
	getSelectedLicenseGroupVault(state)?.vault?.policies?.get(policyId)?.adminPassword;
