import type { PurgeStateAction } from './licenseGroups.types';

export type OrderType = 'DEMO' | 'NFR' | 'PRODUCTION';

export type Receipt = {
	token: string;
	status: 'SUCCESS';
	order: {
		subtotal: number;
		tax: number;
		total: number;
		type: OrderType;
		items: {
			[catalogId: number]: {
				name: string;
				quantity: number;
			};
		};
	};
};

export enum PublicId {
	FreeTrial = '9D9B82FB-0D57-4145-831C-A4AB7257514F',
	NFR = '3611401A-986B-4912-9CE5-BC1A73F8558C',
	StarterMonthly = '00919D56-7103-4397-B9DD-4E8AA42F2863',
	StarterYearly = 'FAKE0000-0000-0000-0000-000000000001',
	StarterYearlyTerm = '112A82E3-3C70-4EDE-B8B2-7E0EFA2E31DF',
	BusinessMonthly = '35F75645-0FA2-4CAF-A25C-71F52BDDD8EC',
	BusinessYearly = 'FAKE0000-0000-0000-0000-000000000003',
	BusinessYearlyTerm = '21D6D08B-6D0B-40D2-B77F-CB7A2D3538A4',
	EnterpriseMonthly = '38C1E8DE-045C-4FF5-B233-768C92858468',
	EnterpriseYearly = 'FAKE0000-0000-0000-0000-000000000005',
	EnterpriseYearlyTerm = 'A511EA9C-D831-4F8F-9D65-2146D35DF66A'
}

export type CatalogItem = {
	publicId: PublicId;
	name: string;
	showPrice: boolean;
	defaultPrice: number;
	priceRanges: {
		amount: number;
		min: number;
		// sap_id: string;
	}[];
	billing: {
		uid: string;
		// name: string;
		// duration: number;
		// months: boolean;
		// gracePeriod: number;
	};
	counter: {
		min: number;
		max: number;
		step: number;
		// lock: boolean;
	};
};

export type MarketState = {
	currency: string;
	catalog: {
		[publicId in PublicId]: CatalogItem;
	};
};

type GetCatalogSuccessAction = {
	type: 'GET_CATALOG_SUCCESS';
	payload: {
		currency: string;
		catalog: {
			[publicId in PublicId]: CatalogItem;
		};
	};
};

export type MarketActionTypes = GetCatalogSuccessAction | PurgeStateAction;
