import React from 'react';

const InvitePageConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/invite/:invitationId/:oneTimeKey',
			exact: true,
			component: React.lazy(() => import('./InvitePage'))
		}
	]
};

export default InvitePageConfig;
