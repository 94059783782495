// import i18next from 'i18next';
// import en from './navigation-i18n/en';

// i18next.addResourceBundle('en', 'navigation', en);

const adminNavigationConfig = [
	{
		id: 'dashboard',
		translate: 'Dashboard',
		type: 'item',
		icon: 'donut_large',
		url: '/admin/dashboard'
	},
	{
		id: 'devices',
		translate: 'Devices',
		type: 'item',
		icon: 'device',
		url: '/admin/devices'
	},
	{
		id: 'policies',
		translate: 'Policies',
		type: 'item',
		icon: 'policy',
		url: '/admin/policies'
	},
	{
		id: 'users',
		translate: 'Users',
		type: 'item',
		icon: 'user',
		url: '/admin/users'
	},
	{
		id: 'logs',
		translate: 'Logs',
		type: 'item',
		icon: 'description',
		url: '/admin/logs'
	},
	{
		id: 'reports',
		translate: 'Reports',
		type: 'item',
		icon: 'reports',
		url: '/admin/reports'
	},
	{
		id: 'settings',
		translate: 'Settings',
		type: 'item',
		icon: 'tune',
		url: '/admin/settings'
	}
	// {
	// 	id: 'subscription-details',
	// 	translate: 'Subscription Details',
	// 	type: 'item',
	// 	icon: 'receipt',
	// 	url: '/admin/modify-subscription'
	// }
];

const navigationConfig = adminNavigationConfig;

export default navigationConfig;
