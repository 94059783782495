import localForage from 'localforage';
import { persistReducer } from 'redux-persist';
import type { RootState } from 'app/store';
import { LicenseGroupsState, LicenseGroupsActionTypes, PublicId } from 'app/store/types';
import { getUsers, getProfile, getOwnerUser } from 'app/store/reducers';

const initialState: LicenseGroupsState = {
	byId: undefined! // HACK::assume never undefined and handle check only in <LicenseGroupWrapper /> and <ToolbarLayoutAdmin />
};

const licenseGroupsReducer = (state = initialState, action: LicenseGroupsActionTypes) => {
	switch (action.type) {
		case 'GET_LICENSE_GROUPS_SUCCESS': {
			return {
				...state,
				byId: action.payload.data
			};
		}
		case 'GET_LICENSE_GROUP_DATA_SUCCESS': {
			const {
				licenseGroupId,
				data: { details }
			} = action.payload;

			return {
				...state,
				byId: {
					...state.byId,
					[licenseGroupId]: {
						...state.byId[licenseGroupId],
						...details,
						// HACK-ish::add prop to say additional data has loaded
						dataLoaded: true
					}
				}
			};
		}
		case 'LOGGED_OUT_USER':
		case 'PURGE_STATE':
			return initialState;
		default: {
			return state;
		}
	}
};

export default persistReducer(
	{
		key: 'licenseGroups',
		storage: localForage,
		blacklist: ['byId'] // re-request license groups every time
	},
	licenseGroupsReducer
);

// Selectors
export const canAppManage = ({ licenseGroups }: RootState) =>
	Object.values(licenseGroups.byId).every(tenant => tenant?.isSelfManagedRequired === false);
export const getLicenseGroups = ({ licenseGroups }: RootState) =>
	licenseGroups.byId ? Object.values(licenseGroups.byId) : undefined!;
export const getSelectedLicenseGroupData = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId && selectedLicenseGroupId ? licenseGroups.byId[selectedLicenseGroupId] : undefined!;
export const getSelectedLicenseGroupVersion = ({ app: { selectedLicenseGroupId }, licenseGroups }: RootState) =>
	licenseGroups.byId[selectedLicenseGroupId].version;
export const getIsSelectedLicenseGroupSubscriptionLicense = ({
	app: { selectedLicenseGroupId },
	licenseGroups
}: RootState) =>
	selectedLicenseGroupId &&
	![
		PublicId.StarterYearlyTerm,
		PublicId.BusinessYearlyTerm,
		PublicId.EnterpriseYearlyTerm,
		PublicId.FreeTrial,
		PublicId.NFR
	].includes(licenseGroups.byId[selectedLicenseGroupId].catalogPublicId);

export const getPermissionCheck = (state: RootState) => {
	const licenseGroupData = getSelectedLicenseGroupData(state) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	if (!licenseGroupData || !licenseGroupData.dataLoaded) {
		return () => false;
	}

	const planPermissionsForSelectedLicenseGroup = licenseGroupData.permissions;

	return (planPermission: string) => !!planPermissionsForSelectedLicenseGroup[planPermission];
};

export const getProfilePermissionCheck = (state: RootState) => {
	const licenseGroupData = getSelectedLicenseGroupData(state) as
		| ReturnType<typeof getSelectedLicenseGroupData>
		| undefined; // HACK::use actual type here - assume never undefined within the wrapped app

	if (!licenseGroupData || !licenseGroupData.dataLoaded) {
		return () => false;
	}

	const users = getUsers(state);
	const profile = getProfile(state);
	const ownerUser = getOwnerUser(state);

	const isOwner = ownerUser.id === profile.id;

	const profilePermissionsForSelectedLicenseGroup = users.find(user => user.id === profile.id)?.permissions ?? {};

	return (profilePermission: string) => {
		// disable all permissions if license expired (besides `license_management`)
		if (
			licenseGroupData.expirationDate &&
			Date.now() > licenseGroupData.expirationDate &&
			profilePermission !== 'license_management'
		) {
			return false;
		}

		// give owner all permissions
		if (isOwner) {
			return true;
		}

		return !!profilePermissionsForSelectedLicenseGroup[profilePermission];
	};
};
