import axios from 'app/client';
import { getIsVaultUnlocked, getSelectedLicenseGroupId, getUserVault as getUserVaultSelector } from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { LicenseGroupData, Policy, VaultLicenseGroupData, VaultType } from 'app/store/types';
import {
	VaultFile,
	UserVault,
	Util,
	GroupVault,
	TransactionLog,
	GroupKeys
} from '@sec/shield-guard-password-management';
import localForage from 'localforage';
import * as _ from 'lodash';
import { RichError } from '@sec/shield-guard-password-management/dist/BaseTypes';
import * as appActions from './app.actions';
import * as deviceActions from './devices.actions';
import * as profileActions from './profile.actions';

const getGroupVaultUrls = async () => {
	const resp = await axios.get('/api/password-management/vault-url/group');
	return resp.data;
};

export const uploadVault = async (vault: UserVault | GroupVault, vaultKey?: string) => {
	const payload = await (await VaultFile.create(vault)).toBuffer();
	const digest = Util.Buffer.toBase64(await crypto.subtle.digest('SHA-1', payload));

	const params = new URLSearchParams();
	if (vault instanceof UserVault) {
		params.set('type', 'user');
	} else {
		params.set('type', 'group');
		params.set('id', vault.vault.id);
	}

	const resp = await axios.post('/api/password-management/vault', payload, {
		params,
		transformRequest: data => data,
		headers: {
			'Content-Type': 'application/octet-stream',
			Digest: `sha=${digest}`,
			...(vaultKey !== undefined && { updateVaultType: true, vaultKey: vaultKey })
		}
	});

	if (resp.status !== 201) {
		throw new Error(`Unexpected status code: ${resp.status}`);
	}
	return true;
};

const getOneTimeKeys = async (): Promise<Record<string, string>> => {
	let oneTimeKeys = (await localForage.getItem('transactionKeys')) ?? {};
	if (typeof oneTimeKeys !== 'object') {
		oneTimeKeys = {};
	} else if (typeof oneTimeKeys === 'object') {
		oneTimeKeys = Object.fromEntries(Object.entries(oneTimeKeys ?? {}).filter(([, v]) => typeof v === 'string'));
	}

	return oneTimeKeys as Record<string, string>;
};

const getOutstandingUserTransactions = async (userVault: UserVault) => {
	const params = new URLSearchParams();
	if (userVault.transactionLog.lastAppliedTransaction !== undefined) {
		params.set('lastApplied', userVault.transactionLog.lastAppliedTransaction.transactionId);
	}

	const resp = await axios.get('/api/password-management/transaction/user', { params });

	return resp.data;
};

const getOutstandingGroupTransactions = async (groupVaults: GroupVault[]) => {
	const params = new URLSearchParams();
	for (const { vault, transactionLog } of groupVaults) {
		if (transactionLog.lastAppliedTransaction !== undefined) {
			params.set(`lastApplied[${vault.id}]`, transactionLog.lastAppliedTransaction.transactionId);
		}
	}

	const resp = await axios.get('/api/password-management/transaction/group', { params });

	return resp.data;
};

export const createVaults = (
	masterKey: string,
	encryptedKey: string,
	licenseGroups: Record<LicenseGroupData['id'], VaultLicenseGroupData>,
	onSuccess?: () => void
): AppThunk => async dispatch => {
	try {
		const userVault = await UserVault.create(masterKey, licenseGroups);
		const userTransactions = await getOutstandingUserTransactions(userVault);
		await userVault.applyTransactions(userTransactions, await getOneTimeKeys());

		const groupVaultBuffers = await getGroupVaults(await getGroupVaultUrls());
		const groupVaults: Map<LicenseGroupData['id'], GroupVault> = new Map();

		const safeGroupVaultBuffers = new Map();
		for (const key of Object.keys(licenseGroups)) {
			// filter out certain group vaults that you are currently blocked from accessing...
			// ie. waiting to get re-invited to multi-user tenants.
			safeGroupVaultBuffers.set(key, groupVaultBuffers.get(key));
		}

		for (const [groupId, buffer] of safeGroupVaultBuffers) {
			if (buffer === null) {
				const keys = userVault.vault.get(groupId)?.keys;
				if (keys === undefined) {
					throw new Error(`Failed to create vault keys for group: ${groupId}`);
				}
				groupVaults.set(groupId, await GroupVault.create(groupId, keys));
			} else {
				const vaultFile = await VaultFile.fromBuffer(buffer, (vaultBuffer, transactionLog) => {
					const group = userVault.vault.get(groupId);
					if (group === undefined) {
						throw new RichError('Missing group keys', {
							error: 'MissingGroupKeys',
							expected: groupId,
							actual: Object.keys(userVault.vault)
						});
					}
					return GroupVault.decrypt(vaultBuffer, group.keys, transactionLog);
				});
				groupVaults.set(groupId, vaultFile.vault);
			}
		}

		const groupTransactions = await getOutstandingGroupTransactions(Array.from(groupVaults.values()));
		for (const [groupId, groupVault] of groupVaults) {
			const transactions = groupTransactions?.[groupId] ?? [];
			groupVault.applyTransactions(transactions);
		}

		const pendingUploads = [];
		if (userVault.needsUpload) {
			pendingUploads.push(uploadVault(userVault, encryptedKey));
		}
		for (const groupVault of groupVaults.values()) {
			if (groupVault.needsUpload) {
				pendingUploads.push(uploadVault(groupVault));
			}
		}

		const results = await Promise.allSettled(pendingUploads);
		for (const result of results) {
			if (result.status === 'rejected') {
				throw new RichError('Failed to upload vault', {
					error: 'VaultUploadFailed',
					original: result.reason
				});
			}
		}

		dispatch({
			type: 'CREATE_VAULTS_SUCCESS',
			payload: { userVault, groupVaults }
		});

		const singleUserTenants: typeof licenseGroups = {};
		for (const [key, value] of Object.entries(licenseGroups)) {
			if (value.currentUsers === 1) singleUserTenants[key] = value;
		}

		dispatch(deviceActions.vaultResetReapplyDevices(singleUserTenants, userVault));
		dispatch(profileActions.getVaultType());
		if (onSuccess) onSuccess();
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const decryptVaults = (
	masterKey: string | ArrayBuffer,
	allLicenseGroups: Record<LicenseGroupData['id'], VaultLicenseGroupData>, //unfiltered license groups
	licenseGroups: Record<LicenseGroupData['id'], VaultLicenseGroupData>, //filtered by vault reset status
	userVaultFileBuffer?: ArrayBuffer,
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async dispatch => {
	try {
		if (userVaultFileBuffer === undefined) {
			throw new Error('Missing user vault file');
		}

		let userVault: UserVault;
		const groupVaults: Map<LicenseGroupData['id'], GroupVault> = new Map();

		try {
			const vaultFile = await VaultFile.fromBuffer(userVaultFileBuffer, (vaultBuffer, transactionLog) => {
				return UserVault.decrypt(vaultBuffer, masterKey, licenseGroups, transactionLog);
			});
			userVault = vaultFile.vault;

			const userTransactions = await getOutstandingUserTransactions(userVault);
			await userVault.applyTransactions(userTransactions, await getOneTimeKeys());
			const groupVaultBuffers = await getGroupVaults(await getGroupVaultUrls());
			const safeGroupVaultBuffers = new Map();
			const isMockUser = localStorage.getItem('mockUser');
			for (const key of Object.keys(licenseGroups)) {
				// filter out certain group vaults that you are currently blocked from accessing...
				// ie. waiting to get re-invited to multi-user tenants (vault reset feature).
				if (!isMockUser)
					safeGroupVaultBuffers.set(key, groupVaultBuffers.get(key));
			}
			for (const [groupId, buffer] of safeGroupVaultBuffers) {
				if (buffer === null) {
					const keys = userVault.vault.get(groupId)?.keys;
					if (keys === undefined) {
						throw new Error(`Missing group keys: ${groupId}`);
					}
					groupVaults.set(groupId, await GroupVault.create(groupId, keys));
				} else {
					const vaultFile = await VaultFile.fromBuffer(buffer, (vaultBuffer, transactionLog) => {
						const group = userVault.vault.get(groupId);
						if (group === undefined) {
							throw new RichError('Missing group keys', {
								error: 'MissingGroupKeys',
								expected: groupId,
								actual: Object.keys(userVault.vault)
							});
						} else {
							return GroupVault.decrypt(vaultBuffer, group.keys, transactionLog);
						}
					});
					groupVaults.set(groupId, vaultFile.vault);
				}
			}
		} catch (error) {
			console.error(error);
			if (typeof error !== 'object' || error === null) {
				throw new Error('Unknown Error');
			}

			const { data } = error as { data: { error: string } };
			if (data?.error === 'InvalidVaultFile') {
				try {
					const transactionLog = await TransactionLog.create();
					userVault = await UserVault.decrypt(userVaultFileBuffer, masterKey, licenseGroups, transactionLog);

					if (userVault.deprecatedVault !== undefined) {
						const lastAppliedTransaction = userVault.deprecatedVault.lastAppliedTransactions.user;
						if (lastAppliedTransaction !== undefined) {
							userVault.transactionLog.write(
								{
									transactionId: lastAppliedTransaction,
									// Rather than spend the effort to lookup
									// the creation time of the last-applied
									// transaction, just use the UNIX epoch. The
									// transaction log uses the creation time to
									// check that an outstanding transaction is
									// not older than the last-applied
									// transaction.
									created: new Date(0)
								},
								userVault.digest
							);
						}
					}

					const userTransactions = await getOutstandingUserTransactions(userVault);
					await userVault.applyTransactions(userTransactions, await getOneTimeKeys());

					const groupVaultBuffers = await getGroupVaults(await getGroupVaultUrls());
					for (const [groupId, buffer] of groupVaultBuffers) {
						if (buffer === null) {
							const keys = userVault.vault.get(groupId)?.keys;
							if (keys === undefined) {
								throw new Error(`Missing group keys: ${groupId}`);
							}
							groupVaults.set(groupId, await GroupVault.create(groupId, keys));
						} else {
							const vaultFile = await VaultFile.fromBuffer(buffer, (vaultBuffer, transactionLog) => {
								const group = userVault.vault.get(groupId);
								if (group === undefined) {
									throw new RichError('Missing group keys', {
										error: 'MissingGroupKeys',
										expected: groupId,
										actual: Object.keys(userVault.vault)
									});
								}
								return GroupVault.decrypt(vaultBuffer, group.keys, transactionLog);
							});
							groupVaults.set(groupId, vaultFile.vault);
						}
					}
				} catch (error) {
					console.error(error);

					if (typeof error === 'object' && error !== null) {
						const { data } = error as { data: { error: string } };
						if (data?.error === 'DecryptionFailed') {
							throw new Error(data.error);
						}
					}

					throw new Error('failed to unlock vault');
				}
			} else if (data?.error === 'DecryptionFailed') {
				throw new Error(data.error);
			} else {
				throw new Error('failed to unlock vault');
			}
		}

		const groupTransactions = await getOutstandingGroupTransactions(Array.from(groupVaults.values()));

		for (const [groupId, groupVault] of groupVaults) {
			const transactions = groupTransactions?.[groupId] ?? [];
			await groupVault.applyTransactions(transactions);
		}

		dispatch({
			type: 'DECRYPT_VAULTS_SUCCESS',
			payload: { userVault, groupVaults }
		});
		onSuccess();

		const pendingUploads = [];
		if (userVault.needsUpload) {
			pendingUploads.push(uploadVault(userVault));
		}
		for (const groupVault of groupVaults.values()) {
			if (groupVault.needsUpload) {
				pendingUploads.push(uploadVault(groupVault));
			}
		}

		const results = await Promise.allSettled(pendingUploads);
		for (const result of results) {
			if (result.status === 'rejected') {
				throw new RichError('Failed to upload vault', {
					error: 'VaultUploadFailed',
					original: result.reason
				});
			}
		}
	} catch (error) {
		if (error instanceof RichError) {
			console.error(error.message, error.data);
		}

		if (error instanceof Error && error.message === 'DecryptionFailed') {
			dispatch(appActions.alert('failed to unlock vault with password', 'error'));
		} else {
			dispatch(appActions.handleError(error));
		}

		onError();
	}
};

const getGroupVaults = async (urls: Record<string, string>): Promise<Map<string, ArrayBuffer | null>> => {
	const entries = Object.entries(urls);
	const results = await Promise.allSettled([
		...entries.map(([, url]) => axios.get(url, { responseType: 'arraybuffer' }))
	]);

	const lockedVaults = (results ?? []).map((result, index) => {
		const [groupId] = entries[index];
		if (result.status === 'rejected') {
			const error = result.reason;
			if (error.isAxiosError && error.response.status === 404) {
				const url = new URL(error.response.config.url);
				console.error(`Missing vault: ${url.pathname}`);
				return { groupId, data: null };
			}
			throw error;
		} else {
			if (result.value.status !== 200) {
				throw new Error(`Unexpected status code: ${result.value.status}`);
			}
			return { groupId, data: result.value.data };
		}
	});

	return lockedVaults.reduce((vaults, value) => {
		return vaults.set(value.groupId, value.data);
	}, new Map());
};

export const getUserVault = (url: string): AppThunk => async dispatch => {
	try {
		const resp = await axios.get(url, { responseType: 'arraybuffer' });

		if (resp.status !== 200) {
			throw new Error(`Unexpected status code: ${resp.status}`);
		}

		dispatch({
			type: 'GET_VAULTS_SUCCESS',
			payload: { lockedUserVault: resp.data }
		});
		dispatch(profileActions.getVaultType());
	} catch (error) {
		console.error(error);
		if ((error as any)?.response?.status === 404) {
			return;
		}
		dispatch(appActions.handleError(error));
	}
};

export const setVaultManualPasswordByPolicy = ({
	policyId,
	manualPassword
}: {
	policyId: Policy['id'];
	manualPassword: string | undefined;
}): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	dispatch({
		type: 'SET_VAULT_MANUAL_PASSWORD_BY_POLICY_SUCCESS',
		payload: {
			licenseGroupId,
			data: {
				policyId,
				manualPassword
			}
		}
	});
};

export const changeVaultType = (
	{
		selectedVaultType,
		password,
		userVault
	}: { selectedVaultType: VaultType; password: string; userVault: UserVault },
	onSuccess = () => {},
	onError = () => {}
): AppThunk => async (dispatch, getState) => {
	try {
		if (selectedVaultType == 'app') {
			const response = await axios.get('/api/v1/vault/appManage');
			const { PlaintextKey, EncryptedKey } = response.data;
			await userVault.setNewMasterKey(PlaintextKey);
			if (userVault.needsUpload) {
				await uploadVault(userVault, EncryptedKey);
				onSuccess();
			}
		} else {
			await userVault.setNewMasterKey(password);
			if (userVault.needsUpload) {
				await uploadVault(userVault, '');
				onSuccess();
			}
		}
		dispatch(profileActions.getVaultType());
	} catch (error) {
		dispatch(appActions.alert('failed to generate vault key', 'warning'));
		onError();
		throw error;
	}
};

export const getVaultData = (filteredLicenseGroups: Record<LicenseGroupData['id'], VaultLicenseGroupData>)
	: AppThunk => async (dispatch, getState) => {
	try {
		// userVault should be unlocked already, get it from redux store.
		const userVault = getUserVaultSelector(getState())!;
		const userTransactions = await getOutstandingUserTransactions(userVault);
		await userVault.applyTransactions(userTransactions, await getOneTimeKeys());
		const groupVaultBuffers = await getGroupVaults(await getGroupVaultUrls());
		const groupVaults: Map<LicenseGroupData['id'], GroupVault> = new Map();
		const safeGroupVaultBuffers = new Map();
		for (const key of Object.keys(filteredLicenseGroups)) {
			// Only set the group vault buffer if the group id is in filteredLicenseGroups.
			safeGroupVaultBuffers.set(key, groupVaultBuffers.get(key));
		}

		for (const [groupId, buffer] of safeGroupVaultBuffers) {
			if (buffer === null) {
				const keys = userVault.vault.get(groupId)?.keys;
				if (keys === undefined) {
					throw new Error(`Failed to create vault keys for group: ${groupId}`);
				}
				groupVaults.set(groupId, await GroupVault.create(groupId, keys));
			} else {
				const vaultFile = await VaultFile.fromBuffer(buffer, (vaultBuffer, transactionLog) => {
					const group = userVault.vault.get(groupId);
					if (group === undefined) {
						throw new RichError('Missing group keys', {
							error: 'MissingGroupKeys',
							expected: groupId,
							actual: Object.keys(userVault.vault)
						});
					}
					return GroupVault.decrypt(vaultBuffer, group.keys, transactionLog);
				});
				groupVaults.set(groupId, vaultFile.vault);
			}
		}

		const pendingUploads = [];
		const groupTransactions = await getOutstandingGroupTransactions(Array.from(groupVaults.values()));
		for (const [groupId, groupVault] of groupVaults) {
			const transactions = groupTransactions?.[groupId] ?? [];
			await groupVault.applyTransactions(transactions);
		}

		dispatch({
			type: 'GET_VAULT_DATA_SUCCESS',
			payload: { userVault, groupVaults}
		})

		if (userVault.needsUpload) {
			pendingUploads.push(uploadVault(userVault));
		}

		for (const groupVault of groupVaults.values()) {
			if (groupVault.needsUpload) {
				pendingUploads.push(uploadVault(groupVault));
			}
		}

		const results = await Promise.allSettled(pendingUploads);
		for (const result of results) {
			if (result.status === 'rejected') {
				throw new RichError('Failed to upload vault', {
					error: 'VaultUploadFailed',
					original: result.reason
				});
			}
		}
	} catch(e) {
		console.error(e)
	}
}

// VAULT schema?
// https://dev2.sec.kmbs.us/wclardy/shield-password-management/-/blob/main/js-lib/src/UserVault.ts#L28

// Interface of getting passwords: we'll have { [deviceId]: passwordString }
// https://dev2.sec.kmbs.us/wclardy/shield-password-management/-/blob/main/js-lib/src/BaseTypes.ts#L92

// If we wanna use UID for deviceId in the long-run USE IT NOW!!!
// otherwise
