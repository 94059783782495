import axios from 'app/client';
import history from '@history';
import { AppThunk } from 'app/store';
// FIXME::fix dependency cycle for persistor
import { persistor } from 'app/store';
import * as appActions from './app.actions';

export const purgeState = (): AppThunk => dispatch => {
	dispatch({ type: 'PURGE_STATE' });
	persistor.purge();
	persistor.flush();
	// Need to reset location for pages to reload properly
	history.replace(history.location);
};

export const getRegion = (): AppThunk => async dispatch => {
	try {
		// use public geolocation API from Personalize
		const { data } = await axios.get('https://static.getpersonalize.com/geolocation', { responseType: 'text' });
		dispatch({
			type: 'GET_REGION_SUCCESS',
			payload: {
				region: data
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
		// re-throw error for handling in <InitializeApp />
		throw error;
	}
};
