import axios from 'app/client';
import { AppThunk } from 'app/store';
import { getSelectedLicenseGroupId, getProfile } from 'app/store/reducers';
import { MpDevice } from 'app/store/types';
import * as appActions from './app.actions';

const massageRawMpDevices = (rawMpDevice: any) => {
	const mpDevice: MpDevice = {
		id: rawMpDevice.id,
		serial: rawMpDevice.serial,
		name: rawMpDevice.name,
		friendlyName: rawMpDevice.friendlyName || undefined,
		family: rawMpDevice.family,
		generation: rawMpDevice.generation,
		productLine: rawMpDevice.productLine,
		licenseGroupId: rawMpDevice.tenantId,
		publicKey: rawMpDevice.publicKey
	};

	return mpDevice;
};


export const getMpDevices = (): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());
	const profile = getProfile(getState());

	try {
		const { data: rawMpDevices } = await axios.get(`/api/v1/user/${licenseGroupId}/${profile.id}/devices`);

		const mpDevices: MpDevice[] = rawMpDevices.map(massageRawMpDevices);

		const validMpDevices = mpDevices.filter(({ generation }) => ['IT5', 'IT6'].includes(generation));

		dispatch({
			type: 'GET_MP_DEVICES_SUCCESS',
			payload: {
				list: validMpDevices
			}
		});
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
