import React, { useState, useEffect } from 'react';
import { Crypto, GroupKeys, UserTransaction, Util } from '@sec/shield-guard-password-management';
import * as Actions from 'app/store/actions';
import axios from 'app/client';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { FileCopyIcon } from 'app/components/Icons';
import { useTranslation } from 'react-i18next';
import { User } from 'app/store/types/licenseGroups.types';
import { useSelector, useDispatch } from 'app/modules/react-redux';
import { isValidEmail } from 'app/utils/helpers';
import VaultDialogWrapper from 'app/components/VaultDialogWrapper';

import { 
    getProfile,
    getUserVault, 
    getSelectedLicenseGroupId,
    getSelectedLicenseGroupData,
	getIsVaultUnlocked,
	getUserReinviteEmail,
	getUser
} from 'app/store/reducers';

type Props = {
    open: boolean;
    handleClose: () => void;
}

const AssistUsersDialog = ({open, handleClose}: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
	const userToReinvite = useSelector(getUserReinviteEmail);	
    const [selectedTab, setSelectedTab] = useState<'email' | 'link'>('email');
    const [emailValue, setEmailValue] = useState(userToReinvite.email);
    const [showInvitationLink, setShowInvitationLink] = useState(false);
    const profile = useSelector(getProfile);
    const userVault = useSelector(getUserVault);
    const [invitationLink, setInvitationLink] = useState('');
    const selectedLicenseGroupId = useSelector(getSelectedLicenseGroupId);
    const { name: selectedLicenseGroupName } = useSelector(getSelectedLicenseGroupData);
	const tenantUser = useSelector(getUser(userToReinvite.id))
    useEffect(() => {
		if (!open) {
			return;
		}

		const getInvitationId = async () => {
			try {
				setInvitationLink('');
				const oneTimeKey = UserTransaction.createOneTimeKey();
				const groupKeys = userVault?.vault.get(selectedLicenseGroupId)?.keys;
				if (groupKeys === undefined) throw new Error('Missing group vault');
				const {
					data: { invitationId }
				} = await axios.post(`/api/v1/invitation`, {
					tenantId: selectedLicenseGroupId,
					userRole: 'admin', // TEMP::add more granular permissions later
					permissions: tenantUser!.permissions,
					createdFor: userToReinvite.id,
					secrets: Util.Buffer.toBase64(
						Crypto.exportEncrypted(
							await Crypto.encryptJson(groupKeys.toJsonValue(), await Crypto.importKdfKey(oneTimeKey))
						)
					)
				});
				setInvitationLink(
					`${window.location.origin}/invite/${encodeURIComponent(invitationId)}/${encodeURIComponent(
						oneTimeKey
					)}`
				);
			} catch (error) {
				dispatch(Actions.handleError(error));
				handleClose();
			}
		};

		if (tenantUser?.permissions !== undefined){
			getInvitationId();
		}
        //TODO:: what's to be done w/ permissions? Not adding/revoking permissions from this dialog.
	}, [dispatch, userVault, open, selectedLicenseGroupId, tenantUser?.permissions]);

	useEffect(() => {
		setEmailValue(tenantUser?.email)
	}, [tenantUser?.email])


    return (
        <Dialog open={open} onClose={() => handleClose()} maxWidth={'xs'} classes={{paper: 'w-437'}}>
            <AppBar position='static'>
                <Toolbar className="flex">
                    <Typography variant="subtitle1" color="inherit">
                        {t('dialog:reinvite user')}
                    </Typography>
                </Toolbar>
            </AppBar>
            <DialogContent classes={{ root: 'p-16 pb-0 xs:p-0 xs:pb-0' }}>
                <DialogContentText variant="caption">
                    {t('dialog:reinvite user text', {name: `${userToReinvite.firstName} ${userToReinvite.lastName}`, tenant: `${selectedLicenseGroupName}`})}
                    <div>
                        {t('dialog:options below')}
                    </div>
                </DialogContentText>
                <Divider />
                <TabContext value={selectedTab}>
                    <TabList
                        onChange={(_event: React.ChangeEvent<{}>, value: 'email' | 'link') => {
                            setSelectedTab(value);
                        }}
                    >
                        <Tab label={t('add users:tab:by email')} value="email" className="normal-case" classes={{root: 'w-1/2' }}/>
                        <Tab label={t('add users:tab:by link')} value="link" className="normal-case" classes={{root: 'w-1/2' }}/>
                    </TabList>
                </TabContext>
                {selectedTab === 'email' && (
                            <div className="flex flex-col items-center mt-16">
                                <TextField
                                    autoFocus
                                    className="w-full mt-16"
                                    value={emailValue}
                                    InputLabelProps={{required: false}} //Still a required field, but the asterisk indicator is gone.
                                    placeholder={t('placeholder:enter email address')}
                                    label={t('User')}
                                    onChange={e => setEmailValue(e.target.value)}
                                    variant="outlined"
                                    required
                                    error={
                                        emailValue !== '' && (!isValidEmail(emailValue!))
                                    }
                                    helperText={
                                        emailValue!== ''
                                            ? !isValidEmail(emailValue!)
                                                ? t('error text:invalid email address')
                                                : undefined
                                            : ''
                                    }
                                />
                                <DialogContent classes={{ root: 'mb-16 px-0 mt-16 font-sans text-xs' }}>
                                    {t('dialog:email reinvite')}
                                </DialogContent>
                            </div>
                        )}
                {selectedTab === 'email' && (
                    <DialogActions className="justify-between p-0 pb-36">
                        <Button
                            component="a"
                            href={`mailto:${encodeURIComponent(emailValue!)}?
                                    subject=${encodeURIComponent(
                                t('invitation:subject', {
                                    invitedBy: `${profile.firstName} ${profile.lastName} (${profile.email})`
                                }) as string
                            )}
&body=${encodeURIComponent(
`${t('reinvitation:body:1')}

${t('invitation:body:2', { name: selectedLicenseGroupName})}
${t('invitation:body:3', { invitedBy: `${profile.firstName} ${profile.lastName} (${profile.email})` })}

${t('reinvitation:body:4')}

${invitationLink}

${t('invitation:body:5')}`
)}`}
                            target="_blank"
                            rel="noreferrer noopener"
                            role="button"
                            variant="contained"
                            color="secondary"
                            onClick={handleClose}
                            disabled={
                                !isValidEmail(emailValue!) ||
                                !invitationLink
                            }
                        >
                            { !invitationLink ? <CircularProgress size={'24px'} style={{ color: '#4caf50', marginInline: '16px' }} /> :  t('vault:reinvite')}
                        </Button>
                    </DialogActions>
                )}
                {selectedTab === 'link' && (
                    <DialogContent classes={{ root: 'p-0 pt-12 text-xs' }}>
                                <DialogContent classes={{ root: 'mb-36 px-0 font-sans' }}>
                                    {t('dialog:link reinvite')}
                                </DialogContent>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={async () => {
                                    setShowInvitationLink(true);
                                    try {
                                        await navigator.clipboard.writeText(invitationLink);
                                        dispatch(Actions.alert('copied to clipboard', 'success'));
                                    } catch (e) {
                                        dispatch(Actions.alert('something went wrong', 'error'));
                                    }
                                }}
                                disabled={!invitationLink || showInvitationLink}
                            >
                                { !invitationLink ? <CircularProgress size={'24px'} style={{ color: '#4caf50', marginInline: '16px' }} />  : t('add users:get link')}
                            </Button>

                            <Tooltip
                                title={
                                        !showInvitationLink
                                        ? t('add users:click get link button') as string
                                        : ''
                                }
                            >
                                <div className="flex items-center mt-16 mb-32">
                                    <TextField
                                        className={clsx('w-full', {
                                            'pointer-events-none select-none':
                                                !showInvitationLink
                                        })}
                                        label={t('add users:invitation link label')}
                                        name="currentPassword"
                                        variant="outlined"
                                        value={showInvitationLink ? invitationLink : '' }
                                        fullWidth
                                        InputProps={{
                                            readOnly: true,
                                            disabled: !showInvitationLink
                                        }}
                                    />
                                    <div className="flex">
                                        <Tooltip title={t('view password:copy') ?? ''}>
                                            <IconButton
                                                onClick={async () => {
                                                    try {
                                                        await navigator.clipboard.writeText(invitationLink);
                                                        dispatch(Actions.alert('copied to clipboard', 'success'));
                                                    } catch (e) {
                                                        dispatch(Actions.alert('something went wrong', 'error'));
                                                    }
                                                }}
                                                disabled={
                                                    !invitationLink || !showInvitationLink
                                                }
                                                size="large">
                                                <FileCopyIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>
                            </Tooltip>
                        </DialogContent>
                        )}
            </DialogContent>
        </Dialog>
    );

}

export default AssistUsersDialog;