import axios from 'axios';
import { mocking } from './mocking';

const instance = axios.create({
	// baseURL: 'http://localhost:9200', // do not prepend URL because it can be determined from browser
	responseType: 'json',
	transformRequest: JSON.stringify,
	headers: {
		'Content-Type': 'application/json'
	}
});

export default instance;

mocking(instance, true);
