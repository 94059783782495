import axios from 'app/client';
import { AxiosError } from 'axios';
import _ from '@lodash';
import { responseErrors } from 'app/utils/helpers';
import { getDevicesDataHack } from 'app/utils/hacks';
import {
	getSelectedLicenseGroupId,
	getMpDevices,
	getDevices,
	getPolicyById,
	getAdminPasswordByPolicyId,
	getDeviceGroupById,
	getUserVault,
	getSelectedLicenseGroupData
} from 'app/store/reducers';
import { AppThunk } from 'app/store';
import { Device, Policy, VaultLicenseGroupData } from 'app/store/types';
import { Crypto, GroupKeys, UserVault, Util } from '@sec/shield-guard-password-management';
import { Utf8 } from '@sec/shield-guard-password-management/dist/Util';
import * as appActions from './app.actions';
import * as licenseGroupsActions from './licenseGroups.actions';
import * as policyActions from './policies.actions';

export const createChangeAdminPasswordTask = async ({
	ecdh,
	licenseGroupId,
	groupKeys,
	device,
	policy,
	password
}: {
	ecdh: Crypto.Ecdh;
	licenseGroupId: string;
	groupKeys?: GroupKeys;
	device: Device;
	policy: Policy;
	password?: string;
}) => {
	if (_.isNil(device?.publicKey) || _.isNil(groupKeys)) return;

	const isManual = policy.passwordConfig.passwordGenerationType === 'manual';
	if (!isManual || !_.isString(password) || _.isEmpty(password)) return;

	const secrets = Utf8.encode(password);
	const { publicKey, ciphertext } = await ecdh.encrypt(secrets, device.publicKey);
	const publicKeyDigest = Util.Buffer.toBase64(
		await crypto.subtle.digest('SHA-1', Util.Buffer.fromBase64(device.publicKey))
	);

	return {
		type: 'change-admin-password',
		parameters: {
			groupId: licenseGroupId,
			devicePublicKeyDigest: `SHA=${publicKeyDigest}`,
			publicKey: Util.Buffer.toBase64(publicKey)
		},
		secrets: Util.Buffer.toBase64(ciphertext)
	};
};

export const addDevicesToDeviceGroup = (
	serials: string[],
	deviceGroupId: string | undefined,
	rotatePassword = true
): AppThunk => async (dispatch, getState) => {
	const ecdh = await Crypto.Ecdh.create();
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const userVault = getUserVault(state);
	const deviceGroup = deviceGroupId ? getDeviceGroupById(deviceGroupId)(state) : undefined;
	const policy = deviceGroup?.policyId ? getPolicyById(deviceGroup.policyId)(state) : undefined;
	const devices = _.keyBy(getDevices(state) || [], 'serial');
	const licenseGroupData = getSelectedLicenseGroupData(getState());

	try {
		const params = [];
		for (const serial of serials) {
			const device = devices[serial];
			const password = policy ? getAdminPasswordByPolicyId(policy.id)(state) : undefined;
			params.push({
				deviceId: serial,
				deviceGroupId: deviceGroupId ?? null,
				tenantUpdatedAt: licenseGroupData.tenantUpdatedAt + '',
				changeAdminPasswordTask:
					!policy || _.isNil(password)
						? undefined
						: await createChangeAdminPasswordTask({
								ecdh,
								licenseGroupId,
								groupKeys: userVault?.vault.get(licenseGroupId)?.keys,
								policy,
								device,
								password
						  })
			});
		}

		const responses = await Promise.all(
			_.chunk(params, 25).map(payload => axios.patch(`/api/v1/device/${licenseGroupId}`, payload))
		);
		if (responseErrors(responses).length) {
			if (responses[0].data[0].returnCode === 409)
				return dispatch(appActions.alert('failed to update data for this tenant - please refresh', 'warning'));
			dispatch(appActions.alert('failed to add some devices to device group', 'warning'));
		} else {
			try {
				if (policy && policy.passwordConfig.passwordGenerationType === 'random' && rotatePassword) {
					await dispatch(
						policyActions.submitPasswordChange({
							policyId: policy.id,
							passwordConfig: policy.passwordConfig
						})
					);
				}
				dispatch(appActions.alert('devices added to device group', 'success'));
			} catch {
				dispatch(appActions.alert('failed to update password configuration', 'warning'));
			}
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const assignPolicyToDevices = (
	serials: string[],
	policyId: string | undefined,
	rotatePassword = true,
	hideBanners = false
): AppThunk => async (dispatch, getState) => {
	const ecdh = await Crypto.Ecdh.create();
	const state = getState();
	const licenseGroupId = getSelectedLicenseGroupId(state);
	const userVault = getUserVault(state);
	const policy = policyId ? getPolicyById(policyId)(state) : undefined;
	const devices = _.keyBy(getDevices(state) || [], 'serial');
	const licenseGroupData = getSelectedLicenseGroupData(getState());

	try {
		const params = [];
		for (const serial of serials) {
			const device = devices[serial];
			const password = policy ? getAdminPasswordByPolicyId(policy.id)(state) : undefined;
			params.push({
				deviceId: serial,
				policyId: policyId ?? null,
				tenantUpdatedAt: licenseGroupData.tenantUpdatedAt + '',
				changeAdminPasswordTask:
					!policy || _.isNil(password)
						? undefined
						: await createChangeAdminPasswordTask({
								ecdh,
								licenseGroupId,
								groupKeys: userVault?.vault.get(licenseGroupId)?.keys,
								policy,
								device,
								password
						  })
			});
		}

		const responses = await Promise.all(
			_.chunk(params, 25).map(payload => axios.patch(`/api/v1/device/${licenseGroupId}`, payload))
		);
		if (responseErrors(responses).length) {
			if (responses[0].data[0].returnCode === 409)
				return dispatch(appActions.alert('failed to update data for this tenant - please refresh', 'warning'));
			if (!hideBanners) {
				dispatch(appActions.alert('failed to assign policy to some devices', 'warning'));
			}
		} else {
			try {
				if (
					serials.length &&
					policy &&
					policy.passwordConfig.passwordGenerationType === 'random' &&
					rotatePassword
				) {
					await dispatch(
						policyActions.submitPasswordChange({
							policyId: policy.id,
							passwordConfig: policy.passwordConfig
						})
					);
				}
				if (!hideBanners) {
					dispatch(appActions.alert('policy assigned', 'success'));
				}
			} catch (error) {
				if (!hideBanners) {
					dispatch(appActions.alert('failed to update password configuration', 'warning'));
				}
			}
		}

		if (!hideBanners) {
			dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
		}
	} catch (error) {
		if (!hideBanners) {
			dispatch(appActions.handleError(error));
		} else {
			console.error(error);
		}
	}
};

export const addDevices = (serials: string[]): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const userVault = getUserVault(state);
	const licenseGroupId = getSelectedLicenseGroupId(state);

	const devicesData = getDevicesDataHack(licenseGroupId, serials, getMpDevices(getState())!);

	for (const device of devicesData) {
		const groupKeys = userVault?.vault.get(licenseGroupId)?.keys;
		if (device.publicKey && groupKeys) {
			const digest = await crypto.subtle.digest('SHA-1', Util.Buffer.fromBase64(device.publicKey));
			const { publicKey, ciphertext } = await groupKeys.encrypt(device.publicKey);
			device.setVaultKeysTask = {
				parameters: {
					groupId: licenseGroupId,
					publicKey: Util.Buffer.toBase64(publicKey),
					devicePublicKeyDigest: `SHA=${Util.Buffer.toBase64(digest)}`
				},
				secrets: Util.Buffer.toBase64(ciphertext)
			};
		}
	}

	try {
		const responses = await Promise.all(_.chunk(devicesData, 25).map(chunk => axios.post(`/api/v1/device`, chunk)));

		// TODO::we're not handling `responseError` vs `responseErrors` correct in general - both in the shape they expect and which we use per action
		if (responseErrors(responses).length) {
			if (
				responseErrors(responses).some(
					(error: string | { returnCode: string }) =>
						typeof error !== 'string' && `${error.returnCode}` === '402'
				)
			) {
				dispatch(appActions.alert('failed to add some devices due to device limit', 'warning'));
			} else {
				dispatch(appActions.alert('failed to add some devices', 'warning'));
			}
		} else {
			dispatch(appActions.alert('devices added', 'success'));
		}
		dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const removeDevices = (serials: string[]): AppThunk => async (dispatch, getState) => {
	const licenseGroupId = getSelectedLicenseGroupId(getState());

	try {
		// const responses = await Promise.all(
		// 	_.chunk(serials, 25).map(chunk =>
		// 		axios.delete(`/api/v1/device/${licenseGroupId}`, {
		// 			params: { deviceId: chunk },
		// 			paramsSerializer: params => qs.stringify(params, { arrayFormat: 'brackets' })
		// 		})
		// 	)
		// );
		// TEMP::missing bulk delete so looping per action
		const responses = await Promise.all(
			serials.map(serial => axios.delete(`/api/v1/device/${licenseGroupId}/${serial}`))
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to remove some devices', 'warning'));
		} else {
			dispatch(appActions.alert('devices removed', 'success'));
		}
		// HACK::something weird is happening with React and we get errors here if we grab new data. (https://dev2.sec.kmbs.us/shield-guard/shield-guard-portal/-/issues/135) Hopefully this is temp...
		// dispatch(licenseGroupsActions.getSelectedLicenseGroupData());
		window.location.reload();
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};

export const vaultResetReapplyDevices = (
	singleUserTenants: Record<string, VaultLicenseGroupData>,
	userVault: UserVault
): AppThunk => async (dispatch, getState) => {
	try {
		let devicesData = [];
		let devicesData2:any = [];
		for (const key of Object.keys(singleUserTenants)){
			devicesData = [];
			devicesData2 = [];
			const response = await axios.delete(`/api/v1/device/${key}`)
			if (response.status === 200 && response?.data?.length){
				devicesData = response.data.map((item: any) =>{
					const { 
						policyId,
						onlineStatus,
						onlineStatusTs,
						lastSettingsCheckTs,
						changeAdminPasswordTask,
						adminPassStatusTs,
						adminPassStatus,
						assessmentId,
						localIp,
						...rest
					} = item;
					return {
						...rest
					};
				});
				devicesData2 = response.data.map((item: unknown) => item);
			}

			for (const device of devicesData) {
				const groupKeys = userVault?.vault.get(key)?.keys;
				if (device.publicKey && groupKeys) {
					const digest = await crypto.subtle.digest('SHA-1', Util.Buffer.fromBase64(device.publicKey));
					const { publicKey, ciphertext } = await groupKeys.encrypt(device.publicKey);
					device.setVaultKeysTask = {
						parameters: {
							groupId: key,
							publicKey: Util.Buffer.toBase64(publicKey),
							devicePublicKeyDigest: `SHA=${Util.Buffer.toBase64(digest)}`
						},
						secrets: Util.Buffer.toBase64(ciphertext)
					};
				}
				// added to the request body for each device in the array
				device.tenantId = key;
			}
		
			if (singleUserTenants[key].expired){
				//don't post devices back to an expired tenant.
				continue;
			}
			await Promise.all(_.chunk(devicesData, 25).map(chunk => axios.post(`/api/v1/device`, chunk)));
			for (const device of devicesData2) {
				dispatch(assignPolicyToDevices([device.deviceId], device.policyId, undefined, true));
			}
		}
	} catch (error) {
		console.error(error);
	}
};

export const changeDevicePassword = (
	serials: string[],
	{ oldPassword, newPassword }: { oldPassword: string; newPassword: string }
): AppThunk => async dispatch => {
	try {
		const responses = await Promise.all(
			_.chunk(serials, 25).map(chunk =>
				axios.post(`/api/mp/device-password`, { serials: chunk, oldPassword, newPassword })
			)
		);
		if (responseErrors(responses).length) {
			dispatch(appActions.alert('failed to change password for some devices', 'warning'));
		} else {
			dispatch(appActions.alert('password changed', 'success'));
		}
	} catch (error) {
		dispatch(appActions.handleError(error));
	}
};
